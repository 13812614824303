// 兼容ie11
import 'es6-promise/auto' // 自动 polyfill Promise
import 'whatwg-fetch'
import 'custom-event-polyfill'
import 'core-js/stable'
import 'core-js/web/url'
import 'core-js/stable/promise'
import 'core-js/stable/symbol'
import 'core-js/stable/string/starts-with'
import 'regenerator-runtime/runtime'
// 框架相关
import Vue from 'vue'
import App from './App.vue'
import store from './vuex'
import router from './router/router'
import axios from 'axios'

// UI库
import ElementUI from 'element-gui'
import '@gtm-components/element-gui-variables.scss'

import '@/assets/css/iconfont/iconfont.css'
import '@/assets/css/base.scss'

// 内部依赖-GtmUtils工具库
import GtmUtils, { queryToParams, getCookie } from 'web-utils'
import GtmButils, { rootState } from '@gtm-butils'
import '@gtm-components/assets/iconfont/iconfont.css'
import GtmApi, { baseAppApi, sysappApi } from '@gtm-api'
import GtmComponents from '@gtm-components'
import initAxios from '@gtm-api/axios-config'
import { tenantConfigList } from '@gtm-butils/root-state/tenantConfig'
import { loadMicroApp } from 'qiankun'

Vue.use(ElementUI)
Vue.use(GtmUtils)
Vue.use(GtmButils)
Vue.use(GtmApi)
Vue.use(GtmComponents)

Vue.config.productionTip = false
initAxios.initAxiosInterceptor()
Vue.prototype.$loadMicroApp = loadMicroApp

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Rejection:', event.reason)
  // 根据需要处理错误
})

// 这里是请求原始租户信息
const requestUserTenantInfo = () => {
  let params = {}
  let query = queryToParams()
  const token = getCookie('SESSION_TOKEN')
  if (query?.token || token) {
    params = {
      token: query?.token || token
    }
  }
  return baseAppApi.USER.fetchGetUserTenant(params)
    .then(res => {
      return res
    })
}

// 请求游客IP地址
const requestCustomerIp = () => {
  let customerIp = localStorage.getItem('CUSTOMER_IP')
  if (!customerIp) {
    axios.get(`/source/addr`)
      .then(res => {
        if (res && res.data) {
          localStorage.setItem('CUSTOMER_IP', res.data)
        } else {
          localStorage.setItem('CUSTOMER_IP', Math.floor(10000000 + Math.random() * 90000000))
        }
      })
  }
}

// 请求全局租户配置
const requestTenantBusinessConfig = () => {
  return sysappApi.SETTING.fetchGetTenantBusinessSetting()
    .then(res => {
      return res
    })
}

// 执行任务队列
const taskList = [requestUserTenantInfo(), requestTenantBusinessConfig()]
Promise.all(taskList)
  .then(res => {
    // 原始租户相关信息
    const userTenantRes = res[0]
    // 全局租户配置相关信息
    const tenantBusinessRes = res[1] || {
      data: {
        styleTemplate: 'DEFAULT'
      }
    }

    // 原始租户进行全局存储
    rootState.tenantInfo = userTenantRes.data || {}
    sessionStorage.setItem('TENANT_DOMAIN', userTenantRes?.data?.domain || '')
    sessionStorage.setItem('TENANT_ORIGIN_DOMAIN', userTenantRes?.data?.originDomain || '')
    sessionStorage.setItem('TENANT_INFO', JSON.stringify(userTenantRes?.data))
    console.log('【base-platform】【main.js】获取租户原始域名：', userTenantRes?.data?.domain || '')

    // 租户全局配置全局存储【菜单】
    const sortMenus = ['Home', 'InstitutionList', 'NewsList', 'About']
    let targetMenus = []
    const tenantMenus = tenantBusinessRes?.data?.menus ? tenantBusinessRes.data.menus.split(',') : []
    if (tenantMenus.length > 0) {
      sortMenus.forEach(item => {
        if (tenantMenus.includes(item)) {
          targetMenus.push(item)
        }
      })
    }
    rootState.tenantMenus = targetMenus.length > 0 ? targetMenus : ['Home', 'InstitutionList', 'NewsList', 'About']

    // 根据租户全局配置，再结合本地配置，输出最终的配置文件信息
    if (tenantBusinessRes?.data?.styleTemplate === 'DEFAULT') {
      rootState.tenantConfig = {}
      sessionStorage.setItem('TENANT_STYLE_TEMPLATE', 'DEFAULT')
    } else if (tenantBusinessRes?.data?.styleTemplate === 'TRADE_PROFESSIONAL') {
      sessionStorage.setItem('TENANT_STYLE_TEMPLATE', 'TRADE_PROFESSIONAL')
      console.log('【base-platform】【main.js】生效交易配置', tenantBusinessRes.data.global)
      const localConfig = tenantConfigList.find(item => item.host === sessionStorage.getItem('TENANT_DOMAIN')) || {}
      rootState.tenantConfig = {
        header: {
          logoSrc: tenantBusinessRes?.data?.global?.logoSrc,
          platformName: tenantBusinessRes?.data?.global?.platformName
        },
        footer: {
          imageList: tenantBusinessRes?.data?.global?.imageList ? JSON.parse(tenantBusinessRes?.data?.global?.imageList) : [],
          platformInfo: {
            infoItemList: tenantBusinessRes?.data?.global?.platformInfo ? JSON.parse(tenantBusinessRes?.data?.global?.platformInfo) : []
          },
          linkList: localConfig?.footer?.linkList ? localConfig?.footer?.linkList : []
        }
      }
      if (localConfig?.home) {
        rootState.tenantConfig.home = localConfig?.home
      }
      if (localConfig?.institutionList) {
        rootState.tenantConfig.institutionList = localConfig?.institutionList
      }
    }
    console.log('【base-platform】【main.js】获取租户原始域名下的租户配置', rootState.tenantConfig)

    // 请求游客IP地址并全局设置
    requestCustomerIp()
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
