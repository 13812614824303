<!-- 气泡展示  文字超出才展示-->
<template>
  <el-tooltip effect="dark" :content="content" placement="top" :disabled="!isShowTooltip" :max-width="maxWidth">
    <div
      class="gtm-text-overflow"
      @mouseenter="visibilityChange($event)"
      :class="{ tooltipClass: isShowTooltip, boldFont: isBoldFont }"
    >
      {{ content }}
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'GtmBaseTooltip',
  components: {},
  props: {
    maxWidth: {
      type: String,
      default: '800'
    },
    isBoldFont: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShowTooltip: false
    }
  },

  computed: {},

  mounted () {
  },

  methods: {
    visibilityChange (event) {
      let ev = event.srcElement
      // ev; // 文本容器宽度(父节点)
      const evWeight = ev.scrollWidth // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const contentWeight = ev.clientWidth // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      // console.log(ev,ev.scrollWidth,ev.clientWidth);
      if (evWeight > contentWeight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true
      } else {
        // 否则为不溢出
        this.isShowTooltip = false
      }
      // console.log(this.isShowTooltip);
      // this.$emit('visibilityChange',this.isShowTooltip);
    }
  }
}
</script>
<style lang="scss">
.boldFont {
  font-weight: bold;
}
</style>
