import { queryToParams, paramsToQuery, setCookie, getCookie } from './utils/gtm-url'
import { timestampToTime } from './utils/gtm-time'
import {
  gtmDebounce,
  deepClone,
  fileSizeFormat,
  isJSON,
  dateFormat,
  getMimeTypeBySuffix,
  downloadFile,
  getOSAndVersion,
  getBrowserInfoAndScreenResolution
} from './utils/gtm-tool'
import { initCityData } from './utils/gtm-city'
import { bigNumberFormat, bigNumberToFixed, bigNumberAdd, bigNumberMul } from './utils/gtm-number'
import { agentPhoneValid, agentEmailValid, accountValid } from './utils/gtm-rule'
import { watermark } from './utils/gtm-watermark'

export {
  queryToParams,
  paramsToQuery,
  setCookie,
  getCookie,
  timestampToTime,
  gtmDebounce,
  deepClone,
  fileSizeFormat,
  isJSON,
  dateFormat,
  initCityData,
  getMimeTypeBySuffix,
  downloadFile,
  getOSAndVersion,
  getBrowserInfoAndScreenResolution,
  bigNumberFormat,
  bigNumberToFixed,
  bigNumberAdd,
  bigNumberMul,
  agentPhoneValid,
  agentEmailValid,
  accountValid,
  watermark
}

export default {
  install (Vue) {
    Vue.prototype.$gtmUtils = {
      queryToParams,
      paramsToQuery,
      setCookie,
      getCookie,
      timestampToTime,
      gtmDebounce,
      deepClone,
      fileSizeFormat,
      isJSON,
      dateFormat,
      initCityData,
      getMimeTypeBySuffix,
      downloadFile,
      bigNumberFormat,
      bigNumberToFixed,
      bigNumberAdd,
      bigNumberMul,
      agentPhoneValid,
      agentEmailValid,
      accountValid,
      watermark
    }
  }
}
