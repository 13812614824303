const portalMenu = [
  {
    name: '首页',
    routeName: 'Home',
    isActive: false,
    path: '/home',
    appName: 'portal',
    auth: false,
    aside: false,
    header: true
  },
  {
    name: '保函申请',
    routeName: 'InstitutionList',
    isActive: false,
    path: '/institutionList',
    appName: 'guarantee-web',
    auth: false,
    aside: false,
    header: true
  },
  {
    name: '新闻资讯',
    routeName: 'NewsList',
    isActive: false,
    path: '/news',
    appName: 'portal',
    auth: false,
    aside: false,
    header: true
  },
  {
    name: '新闻详情',
    routeName: 'NewsDetail',
    isActive: false,
    path: '/newsDetail',
    activePath: 'portal/news',
    appName: 'portal',
    auth: false,
    aside: false,
    header: true
  },
  {
    name: '关于我们',
    routeName: 'About',
    isActive: false,
    path: '/about',
    appName: 'portal',
    auth: false,
    aside: false,
    header: true
  }
]

const platformMenu = [
  {
    name: '保函预览',
    routeName: 'guaranteeView',
    path: '/guaranteeView',
    appName: 'platform',
    auth: false,
    aside: false
  },
  {
    name: '我的订单',
    routeName: 'orderList',
    path: '/userAbout/orderList',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '我的订单',
    routeName: 'orderAdminList',
    path: '/userAbout/orderAdminList',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '结算中心',
    routeName: 'settlementList',
    path: '/userAbout/settlementList',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '我的信息',
    routeName: 'MineInfo',
    path: '/userAbout/mineinfo',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '实名认证',
    routeName: 'authAboutPage',
    path: '/userAbout/authAboutPage',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '资料管理',
    routeName: 'attach',
    path: '/userAbout/attach',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '企业认证',
    routeName: 'EnterpriseAuto',
    path: '/userAbout/EnterpriseAuto',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '实名认证',
    routeName: 'RealNameAuth',
    path: '/userAbout/realnameauth',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '地区信息配置',
    routeName: 'systemArea',
    path: '/sysapp/systemArea',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '地区信息配置编辑',
    routeName: 'systemAreaEdit',
    path: '/sysapp/systemAreaEdit',
    appName: 'platform',
    auth: true,
    aside: true
  },
  // 下面验证一下是否需要左侧菜单
  {
    name: '地区信息配置新增',
    routeName: 'systemAreaAdd',
    path: '/sysapp/systemAreaAdd',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '平台iframe嵌套',
    routeName: 'platformIframe/Index',
    path: '/platformIframe/Index',
    appName: 'platform',
    auth: true,
    aside: true
  },
  {
    name: '金融机构配置页面',
    routeName: 'unitConfigPages',
    path: '/sysconfig/unitConfigPage',
    appName: 'platform',
    auth: true,
    aside: false
  },
  {
    name: '金融机构编辑页面',
    routeName: 'unitConfigPageEdits',
    path: '/sysconfig/unitConfigPageEdit',
    appName: 'platform',
    auth: true,
    aside: false
  },
  {
    name: '金融机构新增页面',
    routeName: 'unitConfigPageAdds',
    path: '/sysconfig/unitConfigPageAdd',
    appName: 'platform',
    auth: true,
    aside: false
  }
]

const mixtureMenu = [...portalMenu, ...platformMenu]

export { portalMenu, platformMenu, mixtureMenu }
