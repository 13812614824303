<template>
  <el-header class="gtm-header" :style="{ height }">
    <div class="gtm-header-inner">
      <slot></slot>
      <div v-if="showBack" class="gtm-header-back">
        <i class="el-icon-back" @click.stop="onBack"></i>
        <span type="text" @click.stop="onBack"> 返回 </span>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'GtmHeader',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '4.625rem'
    }
  },
  methods: {
    onBack () {
      this.$emit('back')
    }
  }
}
</script>
