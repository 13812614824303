import tenantManager from './tenant-manager'
import tenantConfig from './tenant-config'
import tenantEffective from './tenant-effective'
import tenantProduct from './tenant-product'
import tenantSetting from './tenant-setting'

export default {
  ...tenantManager,
  ...tenantConfig,
  ...tenantEffective,
  ...tenantProduct,
  ...tenantSetting
}
