import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 租户配置-详细配置项
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetTenantBusinessSetting
   */
  fetchGetTenantBusinessSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/tenant`, { params })
      .then(res => res.data)
  }
}
