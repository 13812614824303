<!--
  @name: 对接交易底部板式（根据租户获取文案信息）
  @description: 配置了租户信息的用这个板式
  @author: 马群
  @time: 2024-07-22 09:33:16
-->
<template>
  <div class="gtm-trade-footer-com">
    <div class="main-content">
      <div v-if="linkList.length > 0" class="link-content">
        <div class="label-box">
          友情链接：
        </div>
        <div class="select-box">
          <div v-for="linkItem in linkList" :key="linkItem.name" class="select-item">
            <el-select
              style="width: 100%"
              icon="el-icon-caret-top"
              :placeholder="linkItem.name"
              :value="null"
              @change="onLinkChange"
            >
              <el-option
                v-for="item in linkItem.linkItemList"
                :key="item.name"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="line-box"></div>
      <div
        class="info-content"
        :class="{ 'info-content--top0': linkList.length === 0, 'info-content--center':  imageList.length === 0 || realPlatformInfoList.length === 0 }"
      >
        <div
          class="logo-box" :class="{ 'info-content--center': realPlatformInfoList.length === 0 }"
          v-if="imageList.length > 0"
        >
          <div class="logo-box-inner" :class="{ 'logo-box--inner--has-content': realPlatformInfoList.length > 0 }">
            <img
              class="img-item"
              v-for="(item, index) in imageList" :key="index"
              style="cursor: pointer"
              :src="item.imageSrc"
              @click="onInfoClick(item)"
            >
          </div>
        </div>
        <div class="vertical-line" v-if="!(imageList.length === 0 || realPlatformInfoList.length === 0)"></div>
        <div class="info-box" v-if="realPlatformInfoList.length > 0">
          <div class="info-box-inner">
            <div v-for="(item, index) in realPlatformInfoList" :key="index">
              <div v-if="item.value" :class="item.linkSrc ? 'link-style': ''" @click="onInfoClick(item)">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GtmTradeFooterCom',
  components: {},
  props: {},
  data () {
    return {
      tenantConfig: JSON.parse(sessionStorage.getItem('portal_root_tenantConfig')),
      value: 1,
      linkList: [],
      imageList: [],
      platformInfo: {},
      footerConfigList: []
    }
  },
  computed: {
    realPlatformInfoList () {
      let infoItemList = this.tenantConfig?.footer?.platformInfo?.infoItemList || []
      return infoItemList.filter(item => item.value)
    }
  },
  mounted () {
    if (this.tenantConfig?.footer?.linkList) {
      this.linkList = this.tenantConfig.footer.linkList
    }
    if (this.tenantConfig?.footer?.platformInfo) {
      this.platformInfo = this.tenantConfig.footer.platformInfo
    }
    if (this.tenantConfig?.footer?.imageList) {
      this.imageList = this.tenantConfig.footer.imageList
    }
    console.log(this.realPlatformInfoList, 'infoItemList')
  },
  methods: {
    onLinkChange (item) {
      if (item.linkSrc) {
        window.open(item.linkSrc, '_blank')
      }
    },
    onInfoClick (item) {
      if (item.linkSrc) {
        window.open(item.linkSrc, '_blank')
      }
    }
  }
}
</script>
<style lang="scss">
.gtm-trade-footer-com {
  color: #99B7E9;
  width: 100%;
  background-color: #004AC9;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .main-content {
    display: flex;
    width: 1440px;
    flex-direction: column;

    .link-content {
      height: 94px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .label-box {
        line-height: 94px;
        font-size: 14px;
        margin-right: 10px;
        min-width: 70px;
      }

      .select-box {
        display: flex;
        align-items: center;
        flex: 1;

        .select-item:last-child {
          margin-right: 0;
        }

        .select-item {
          margin-right: 15px;
          flex: 1;

          .el-select {
            .el-input {
              width: 100%;

              .el-input__inner {
                background: rgba(255, 255, 255, 0.1);
                border: none;
                border-radius: 2px;
                color: #99B7E9;
              }

              .el-input__inner::placeholder {
                color: #99B7E9 !important;
              }

              i {
                font-size: 10px;
              }

              .el-icon-caret-top:before {
                color: #99B7E9;
              }
            }
          }
        }
      }
    }

    .line-box {
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(176, 176, 176, 0.5) 48%, rgba(201, 201, 201, 0) 100%);
    }

    .info-content {
      display: flex;
      min-height: 150px;
      flex-direction: row;
      margin-top: 30px;

      .logo-box {
        width: 470px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 25px;
        position: relative;

        .logo-box-inner {

          img {
            margin-right: 20px;
          }

          img:last-child {
            margin-right: 0px;
          }

          .img-item {
            max-height: 60px;
          }
        }

        .logo-box--inner--has-content {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .vertical-line {
        width: 2px;
        min-height: 150px;
        background: linear-gradient(180deg, rgba(201, 201, 201, 0) 0%, rgba(176, 176, 176, 0.5) 52%, rgba(255, 255, 255, 0) 100%);
      }

      .info-box {
        margin-left: 25px;
        width: 780px;
        font-size: 14px;
        display: flex;
        align-items: center;

        .info-box-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding-top: 20px;
          padding-bottom: 20px;

          div {
            margin-right: 30px;
            line-height: 22px;
          }

          .link-style {
            text-decoration: underline;
            text-underline-offset: 4px; /* 设置下划线与文本的距离 */
            cursor: pointer;
          }
        }
      }
    }

    .info-content--center {
      justify-content: center !important;
    }

    .info-content--top0 {
      min-height: 180px;
      margin-top: 0;
    }
  }
}
</style>
