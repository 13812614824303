let agentPhoneReg = new RegExp(/^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/) // 电话号码 （手机和电话号码）
let emailReg = new RegExp(/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/)
export const agentPhoneValid = (rule, value, callback) => {
  if (rule.required && value === '') {
    callback(new Error('请填写手机号码'))
  } else if (value && !agentPhoneReg.test(value)) {
    callback(new Error('请填写正确格式的手机号码'))
  } else {
    callback()
  }
}
export const agentEmailValid = (rule, value, callback) => {
  // console.log(rule, value, emailReg.test(value));
  if (rule.required && value === '') {
    callback(new Error('请填写电子邮箱'))
  } else if (value && !emailReg.test(value)) {
    callback(new Error('请填写正确格式的电子邮箱'))
  } else {
    callback()
  }
}
export const accountValid = (rule, value, callback) => {
  if (value && !/^\d+$/.test(value)) {
    callback(new Error('请填写数字'))
  } else {
    callback()
  }
}
