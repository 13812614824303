<template>
  <div class="gtm-table-empty">
    <div class="empty-box">
      <div class="empty-image"></div>
      <div class="empty-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GtmTableEmpty'
}
</script>

<style lang="scss">
.gtm-table-empty {
  width: 100%;
  height: 100%;

  .empty-box {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;

    .empty-image {
      width: 162px;
      height: 142px;
      background: url(../../assets/image/gtmEmpty/empty.png);
      background-size: 100% 100%;
      background-position: center center;
    }
  }
}

.el-table {
  //数据为空时的空置状态
  .el-table__empty-block {
    height: calc(100% - 1rem) !important;
  }

  .el-table__body-wrapper {
    height: calc(100% - 50px);
  }

  .el-scrollbar__view {
    height: 100%;
  }
}
</style>
