import GTMLayouts from './packages/gtm-layout'
import GTMBusinessLayouts from './packages/gtm-business-layout'
import GtmDividerTitle from './packages/gtm-divider-title'
import GtmLayoutPagination from './packages/gtm-layout-pagination'
import GtmAreaCascader from './packages/gtm-area-cascader'
import GtmEmpty from './packages/gtm-empty'
import GtmDynamicForm from './packages/gtm-dynamic-form'
import GtmQuickActions from './packages/gtm-quick-actions'
import GtmConsultDialog from './packages/gtm-consult-dialog'
import GtmLoading from './packages/gtm-loading'
import GtmLoadingBox from './packages/gtm-loading-box'
import GtmTableEmpty from './packages/gtm-table-empty'
import GtmBaseTooltip from './packages/gtm-base-tooltip'
import './element-variables.scss'

const components = {
  ...GTMLayouts,
  ...GTMBusinessLayouts,
  ...GtmDividerTitle,
  ...GtmLayoutPagination,
  ...GtmAreaCascader,
  ...GtmEmpty,
  ...GtmDynamicForm,
  ...GtmQuickActions,
  ...GtmConsultDialog,
  ...GtmLoading,
  ...GtmLoadingBox,
  ...GtmTableEmpty,
  ...GtmBaseTooltip
}

const install = function (Vue) {
  Object.keys(components)
    .forEach(key => {
      Vue.use(components[key])
    })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
// 全局引入可以这样
export default {
  install,
  ...components
}
