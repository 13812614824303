import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 查询租户全局配置
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchGetTenantSettingGlobal
   */
  fetchGetTenantSettingGlobal (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/setting/findGlobal`, { params })
      .then(res => res.data)
  },
  /**
   * 保存租户全局配置
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchSaveTenantSettingGlobal
   */
  fetchSaveTenantSettingGlobal (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/setting/saveGlobal`, data, { params })
      .then(res => res.data)
  }
}
